import React, { useState } from 'react';
import { ChakraProvider, Box, Heading, Button, extendTheme, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, VStack, Link } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import headsImage from './heads.png';
import tailsImage from './tails.png';

const theme = extendTheme({
  fonts: {
    body: 'Nanum Gothic Coding, monospace',
    heading: 'Nanum Gothic Coding, monospace',
  },
});

const App = () => {
  const [result, setResult] = useState('heads'); // Set initial state to 'heads'
  const [isFlipping, setIsFlipping] = useState(false);
  const [extraRotation, setExtraRotation] = useState(0);
  const [flipLog, setFlipLog] = useState([]);

  const flipCoin = (e) => {
    setIsFlipping(true);
    const buttonWidth = e.target.offsetWidth;
    const clickPosition = e.nativeEvent.offsetX;
    const isHeads = clickPosition < buttonWidth / 2;
    const additionalRotation = clickPosition >= buttonWidth / 2 ? 180 : 0;
    setExtraRotation(additionalRotation);
    setTimeout(() => {
      const flipResult = isHeads ? 'heads' : 'tails';
      setResult(flipResult);
      setIsFlipping(false);
      setFlipLog((prevLog) => [
        { time: new Date().toLocaleString(), result: flipResult },
        ...prevLog,
      ]);
    }, 3000);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box borderBottom="1px" borderColor="gray.200" py={2} px={4}>
        <Flex justify="space-between" align="center">
          <Box flex="1" textAlign="center">
            <Heading as="h1" size="xl">
              Coin Flip Generator
            </Heading>
          </Box>
        </Flex>
      </Box>
      <VStack minH="100vh" spacing={8} py={8} px={4}>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          <motion.div
            initial={{ color: 'black' }}
            animate={isFlipping ? { color: ['black', 'white', 'black'] } : { color: 'black' }}
            transition={isFlipping ? { repeat: Infinity, duration: 1 } : { duration: 0.5 }}
          >
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              It was {result}!
            </Text>
          </motion.div>
          <Text fontSize="xl" mb={4}>
            Spin the coin below!
          </Text>
          <Box position="relative" display="flex" justifyContent="center">
            <AnimatePresence>
              {isFlipping ? (
                <motion.div
                  key="coin"
                  initial={{ rotateY: 0 }}
                  animate={{ rotateY: 1080 + extraRotation }}
                  transition={{ duration: 3, ease: 'easeInOut' }}
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '50%',
                    position: 'relative',
                    transformStyle: 'preserve-3d',
                  }}
                >
                  <motion.div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      backfaceVisibility: 'hidden',
                      backgroundImage: `url(${headsImage})`,
                      backgroundSize: 'cover',
                    }}
                  />
                  <motion.div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      backfaceVisibility: 'hidden',
                      backgroundImage: `url(${tailsImage})`,
                      backgroundSize: 'cover',
                      transform: 'rotateY(180deg)',
                    }}
                  />
                </motion.div>
              ) : (
                <motion.div
                  key={result}
                  initial={{ opacity: 0, rotateY: result === 'heads' ? 0 : 0 }}
                  animate={{ opacity: 1, rotateY: result === 'heads' ? 0 : 0 }}
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '50%',
                    backgroundImage: `url(${result === 'heads' ? headsImage : tailsImage})`,
                    backgroundSize: 'cover',
                  }}
                />
              )}
            </AnimatePresence>
            <Button
              colorScheme="blue"
              size="lg"
              mt={8}
              onClick={flipCoin}
              width="100%"
              position="absolute"
              bottom="-60px"
              left="0"
            >
              Flip
            </Button>
          </Box>
        </Flex>
        <Box width="100%" maxWidth="600px" mt={100}>
          <Heading as="h2" size="lg" mb={4}>
            Flip Log
          </Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Time</Th>
                <Th>Result</Th>
              </Tr>
            </Thead>
            <Tbody>
              {flipLog.map((flip, index) => (
                <Tr key={index}>
                  <Td>{flip.time}</Td>
                  <Td>{flip.result}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Box maxWidth="600px" mx="auto" mb={16}>
          <Heading as="h2" size="xl" mb={4}>
            About Us
          </Heading>
          <Text fontSize="lg" mb={4}>
            Random Generator Hub is a website dedicated to providing high-quality random generation tools for various purposes. Whether you need to flip a coin, roll dice, or simulate a slot machine, we've got you covered!
          </Text>
          <Text fontSize="lg" mb={4}>
            Our mission is to offer a fun and engaging experience while ensuring fairness and randomness in all our generators. We strive to continuously improve and expand our collection of tools to cater to the diverse needs of our users.
          </Text>
        </Box>
        <Box maxWidth="600px" mx="auto">
          <Heading as="h2" size="xl" mb={4}>
            Support Us
          </Heading>
          <Text fontSize="lg" mb={4}>
            If you find our website useful and would like to support its development and maintenance, please consider making a donation. Your contribution helps us cover the costs of hosting, domain, and ongoing improvements to enhance your experience.
          </Text>
          <Link href="#" color="blue.500">
            Donate via PayPal
          </Link>
        </Box>
      </VStack>
      <Box as="footer" bg="gray.100" py={4} textAlign="center">
        <Text fontSize="sm">
          &copy; {new Date().getFullYear()} Coin Flip Generator. All rights reserved.
        </Text>
        <Text fontSize="sm">
          This website is for entertainment purposes only. No actual money is involved.
        </Text>
      </Box>
    </ChakraProvider>
  );
};

export default App;
